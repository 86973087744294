import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Style from "./style.module.scss";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import SendIcon from "@mui/icons-material/Send";
import UserAutocomplete from "./UserAutoComplete";
import axios from 'axios';

function WebSocketClient() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState("");
  const [ws, setWs] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [messages, setMessages] = useState([]);

  const lastMessageRef = useRef();

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    if (selectedUser) {
      const websocket = new WebSocket(`wss://api.inmaeya.com/ws?username=${selectedUser?.toLowerCase()}`);

      websocket.onopen = () => {
        console.log("WebSocket is open now.");
        setButtonDisabled(false);
      };

      websocket.onmessage = (event) => {
        const data = event.data
        setMessages((prev) =>
          prev.map((item, idx, arr) => {
            if (idx === arr.length - 1 && item.type === "AI") {
              return { ...item, content: item.content + data };
            }
            return item;
          })
        );
      };

      websocket.onclose = () => {
        console.log("WebSocket is closed now.");
        setWs(null);
      };

      websocket.onerror = (error) => {
        console.error("WebSocket Error: ", error);
      };

      setWs(websocket);

      return () => {
        websocket.close();
      };
    }
  }, [selectedUser]);
  useEffect(()=> {

  }, [])
  const sendMessage = () => {
    if (ws) {
      setMessages((prev) => [
        ...prev,
        { type: "Human", content: message },
        { type: "AI", content: "" },
      ]);
      ws.send(JSON.stringify({ message }));
      setMessage("");
    }
  };

  const [users, setUsers] = useState([]);

  useEffect(() => {
      const fetchUsers = async () => {
          try {
              const response = await axios.get('https://api.inmaeya.com/users');
              setUsers(response.data);
          } catch (error) {
              console.error('Error fetching users:', error);
          }
      };

      fetchUsers();
  }, []); // Empty dependency array ensures this runs only once when the component mounts


  return (
    <Box className={Style.Container}>
      <Container maxWidth={"md"} className={Style.MainContainer}>
        <Box className={Style.TopContainer}>
          <img src="/logo.svg" alt="logo" />
          <Typography variant={"h5"} color={"white"}>
            Inmaeya Agent
          </Typography>
        </Box>

        {selectedUser ? (
          <>
            <Box
              className={Style.MessagesContainer}
              style={{ background: "url('pattern-01.png')" }}
            >
              <SimpleBar style={{ padding: "2rem" }}>
                {messages?.map((item, index) => {
                  if (item.content !== "") {
                    return (
                      <Typography
                        key={index}
                        ref={
                          index === messages.length - 1 ? lastMessageRef : null
                        }
                        sx={{
                          textAlign: item.type === "AI" ? "left" : "right",
                          marginLeft: item.type === "AI" ? "0" : "auto",
                          marginRight: item.type === "AI" ? "auto" : "0",
                          backgroundColor:
                            item.type === "AI" ? "#e0f2f1" : "#cfd8dc",
                          padding: "8px",
                          borderRadius: "10px",
                          maxWidth: "70%",
                          marginBottom: "8px",
                        }}
                      >
                        {item.content}
                      </Typography>
                    );
                  }
                  return null;
                })}
              </SimpleBar>
            </Box>

            <Box className={Style.InputContainer} alignItems={"center"}>
              <TextField
                className={Style.TextField}
                fullWidth
                multiline
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Enter a message..."
              />

              <IconButton
                disabled={buttonDisabled}
                className={`${Style.SendIcon} ${
                  buttonDisabled && Style.offline
                }`}
                onClick={sendMessage}
              >
                <SendIcon />
              </IconButton>
            </Box>
          </>
        ) : (
          <Box p={5}>
            <UserAutocomplete
              users={users}
              onUserSelect={handleUserSelect}
            />
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default WebSocketClient;
