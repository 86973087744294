import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

const UserAutocomplete = ({ users, onUserSelect }) => {
  const [value, setValue] = useState(null);
  const userOptions = users.map(user => ({ name: user }));
  
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        let selectedUser;
        if (typeof newValue === 'string') {
          selectedUser = newValue;
          setValue({ name: newValue });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          selectedUser = newValue.inputValue;
          setValue({ name: newValue.inputValue });
        } else {
          selectedUser = newValue ? newValue.name : '';
          setValue(newValue);
        }
        onUserSelect(selectedUser);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(option => inputValue === option.name);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            name: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="user-autocomplete"
      options={userOptions}
      getOptionLabel={option => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            {option.name}
          </li>
        );
      }}
      freeSolo
      renderInput={params => (
        <TextField {...params} variant='standard' label="Select or Add User" />
      )}
    />
  );
};

export default UserAutocomplete;
